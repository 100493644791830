<template>
  <div class="list-point">
    <div class="list-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          ポイント設定
        </h2>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button v-on:click="createPoint()" class="button-create-point">
            新規作成
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row p-3 d-flex">
        <label class="col-sm-12 font-weight-bold pl-0">検索</label>
        <CInput
          class="form-input col-sm-5 mb-3"
          style="padding-left: 0px; margin-bottom: 0px"
          v-model="title"
        />
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListSettingPoints(page)"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listSettingPoint.data"
        :fields="fields"
        :itemsPerPage="10"
      >
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action_type="{ item }">
          <td v-if="item.action_type == 1">
            <b-badge variant="info">ログイン</b-badge>
          </td>
          <td v-if="item.action_type == 2">
            <b-badge variant="info">新規登録</b-badge>
          </td>
          <td v-if="item.action_type == 3">
            <b-badge variant="info">アイテムクリック後</b-badge>
          </td>
          <td v-if="item.action_type == 4">
            <b-badge variant="info">購入後</b-badge>
          </td>
          <td v-if="item.action_type == 5">
            <b-badge variant="info">個別に付与</b-badge>
          </td>
          <td v-if="item.action_type == 6">
            <b-badge variant="info">アンケート回答後</b-badge>
          </td>
          <td v-if="item.action_type == 7">
            <b-badge variant="info">プロファイル記入後</b-badge>
          </td>
          <td v-if="item.action_type == 8">
            <b-badge variant="info">自動付与</b-badge>
          </td>
          <td v-if="item.action_type == 9">
            <b-badge variant="info">スラグ付与後</b-badge>
          </td>
        </template>
        <template v-slot:point_expiration_type="{ item }">
          <td v-if="item.point_expiration_type == 0">
            <b-badge variant="secondary">Not set</b-badge>
          </td>
          <td v-if="item.point_expiration_type == 1">
            <b-badge variant="secondary">Hours</b-badge>
          </td>
          <td v-if="item.point_expiration_type == 2">
            <b-badge variant="secondary">Day</b-badge>
          </td>
          <td v-if="item.point_expiration_type == 3">
            <b-badge variant="secondary">Month</b-badge>
          </td>
          <td v-if="item.point_expiration_type == 4">
            <b-badge variant="secondary">Year</b-badge>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <CButton
              class="mx-1 text-nowrap"
              color="info"
              square
              v-on:click="detailSettingPoint(item.id)"
              v-b-modal.modal-detail
              variant="outline"
              size="sm"
            >
              設定確認
            </CButton>
            <CButton
              class="mx-1 text-nowrap"
              color="primary"
              square
              v-on:click="editSettingPoint(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.modal-comfirm
            >
              編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-point
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSettingPoint.total"
      >
        <p class="font-weight-bold">
          {{ listSettingPoint.total }}件中{{
            listSettingPoint.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSettingPoint.last_page"
      >
        <CPagination
          v-if="listSettingPoint.total"
          :activePage.sync="page"
          :pages="listSettingPoint.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <!-- Modal Delete Setting Point -->
    <b-modal id="delete-modal-point" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>
          削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          @click="removePoint(dataModal)"
          >削除</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary back-btn"
          block
          @click="$bvModal.hide('delete-modal-point')"
        >
          戻る
        </b-button>
      </div>
    </b-modal>
    <!-- Modal Delete Setting Point -->
    <!-- Modal Detail Setting Point -->
    <b-modal id="modal-detail" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>{{ settingPointById.ponit_title }}</h5>
      </div>
      <div>
        <CContainer>
          <CRow>
            <CCol lg="6" class="py-3"
              ><span style="font-weight: bolder">ステップ１:</span
              >タイトル</CCol
            >
            <CCol lg="6" class="py-3">{{ settingPointById.point_title }}</CCol>
          </CRow>
          <CRow>
            <CCol lg="6" class="py-3"
              ><span style="font-weight: bolder">ステップ２:</span
              >付与ポイント</CCol
            >
            <CCol lg="6" class="py-3">{{ settingPointById.point_amount }}</CCol>
          </CRow>
          <CRow>
            <CCol lg="6" class="py-3"
              ><span style="font-weight: bolder">ステップ３:</span>
              ポイント失効設定</CCol
            >
            <CCol
              lg="6"
              class="py-3"
              v-if="settingPointById.point_expiration_type === 0"
              >設定しない</CCol
            >
            <CCol
              lg="6"
              class="py-3"
              v-if="settingPointById.point_expiration_type === 1"
              ><span v-if="settingPointById.point_expiration_setting === 0"
                >ポイント付与後
                {{ settingPointById.point_expiration_setting_value }}
                日後に失効する</span
              >
              <span v-if="settingPointById.point_expiration_setting === 1"
                ><span
                  v-if="settingPointById.point_expiration_setting_value === 0"
                  >毎 日 日後に失効する</span
                ><span
                  v-if="settingPointById.point_expiration_setting_value === 1"
                  >毎 月 07 日後に失効する</span
                >
                <span
                  v-if="settingPointById.point_expiration_setting_value === 2"
                  >毎 年 07 日後に失効する</span
                ></span
              >
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6" class="py-3"
              ><span style="font-weight: bolder">ステップ４:</span>
              ポイント付与条件選択</CCol
            >
            <!-- <CCol lg="6" class="py-3">{{ settingPointById.action_type }}</CCol> -->
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 1">
              <b-badge variant="info">ログイン</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 2">
              <b-badge variant="info">新規登録</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 3">
              <b-badge variant="info">アイテムクリック後</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 4">
              <b-badge variant="info">購入後</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 5">
              <b-badge variant="info">個別に付与</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 6">
              <b-badge variant="info">アンケート回答後</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 7">
              <b-badge variant="info">プロファイル記入後</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 8">
              <b-badge variant="info">自動付与</b-badge>
            </CCol>
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type == 9">
              <b-badge variant="info">スラグ付与後</b-badge>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6" class="py-3"
              ><span style="font-weight: bolder">ステップ５:</span>
              <span v-if="settingPointById.action_type === 1"
                >ポイント付与タイミングを選定</span
              >
              <span v-else-if="settingPointById.action_type === 2"
                >ポイント付与するカバーを選定する</span
              >
              <span v-else-if="settingPointById.action_type === 3"
                >ポイント付与するアイテムまたはコンテンツを選択する</span
              >
              <span v-else-if="settingPointById.action_type === 4"
                >ポイント付与タイミングを選定</span
              >
              <span v-else-if="settingPointById.action_type === 5"
                >ポイント付与するユーザーを選定する</span
              >
              <span v-else-if="settingPointById.action_type === 8"
                >ポイント付与タイミングを選定</span
              >
              <span v-else-if="settingPointById.action_type === 9"
                >ポイント付与するスラグを選定する</span
              ></CCol
            >
            <CCol lg="6" class="py-3" v-if="settingPointById.action_type !== 7">
              {{
                new Date(settingPointById.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </b-modal>
    <!-- Modal Detail Setting Point -->
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListPoint",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.SETTINGPOINT,
      dataModal: "",
      title: "",
      limit: 50,
      page: 1,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    // this.getListSettingPoints(this.shop_id);
    this.getListSettingPoints(this.page);
  },
  computed: {
    ...mapGetters([
      "listSettingPoint",
      "message",
      "success",
      "error",
      "settingPointById",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSettingPoint() {
      this.page = this.listSettingPoint.current_page;
    },
    page() {
      this.getListSettingPoints(this.page);
    },
  },
  methods: {
    ...mapActions({ getListSettingPoint: "getListSettingPoint" }),
    ...mapActions({ getSettingPointById: "getSettingPointById" }),
    ...mapActions({ deletePoint: "deletePoint" }),
    getListSettingPoints(page) {
      if (page === undefined) {
        page = this.page;
      }
      const data = {
        page: page,
        data: {
          shop_id: this.shop_id,
          key: this.title,
          limit: this.limit,
        },
      };
      this.getListSettingPoint(data);
    },
    createPoint() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "create point"
          : "create point domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    removePoint(id) {
      let shop_id = this.shop_id;
      const formData = {
        shop_id: shop_id,
        id: id,
        is_active: false,
      };
      this.deletePoint(formData);
      const Data = {
        page: this.page,
        data: {
          shop_id: shop_id,
          key: this.title,
          limit: this.limit,
        },
      };
      this.$nextTick(() => {
        this.$bvModal.hide("delete-modal-point");
        this.getListSettingPoints(Data.page);
      });
    },
    detailSettingPoint(id) {
      this.dataModal = id;
      const dataRequest = {
        id: id,
        shop_id: this.shop_id,
      };
      this.getSettingPointById(dataRequest);
    },
    editSettingPoint(id) {
      this.$router.push({
        name: this.$route.params.shopId ? "edit point" : "edit point domain",
        params: { id: id },
      });
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    resetFieldSearch() {
      this.title = null;
      this.getListSettingPoints(1);
    },
  },
};
</script>
